#siteAnnouncement {
    padding: 10px 15px;
    background-color: #224229;
 
  }
  #siteAnnouncement p {
    margin: 0;
    padding: 0;
    line-height: 1.3em;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    font-family: "Open Sans", sans-serif;
  }
