@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=League+Spartan:wght@700&family=Lobster&family=Nunito+Sans:wght@200;600;700&family=Playfair+Display&family=Roboto:wght@900&display=swap');
.ProductDetails {
  background-color: rgb(255, 255, 255);

  max-width: 100%;

  box-sizing: border-box;
  display: flex;
}

.ProductDetails > div {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 2vmax;
  box-sizing: border-box;
  border: 1px solid white;
}

.ProductDetails > div:last-child {
  align-items: flex-start;
}

.CarouselImage {
  width: 48vmax;
}
.detailsBlock-1 {
  margin-bottom: 20px;
}

.detailsBlock-1 > h2 {

  font-family: Domain display;

  color: #214216;
  font-size: 31px;
  line-height: 150%;

}

/* new from here  */

.size{

  list-style: none;
  display: flex;
  
  padding: 10px;
  
}

.sizeicon{

  border: 1px solid #dbdbdb;
    padding: 8px;
    margin: 4px;
    cursor: pointer;
}
.clicked {
  background-color: #ffcc00; /* Change to the desired background color when clicked */
}
/* to here */

.detailsBlock-1 > p {
  color: rgba(54, 54, 54, 0.582);
  font: 300 0.6vmax;
  font-size: 16px;
  font-family: 'Chilanka';
}

.detailsBlock-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 1vmax 0;
}

.detailsBlock-2-span {
  font: 200 0.8vmax cursive;
  color: rgba(0, 0, 0, 0.699);
}

.detailsBlock-3 {
  width: 100%;
}

.detailsBlock-3 > h1 {
 
  font-family: Domain display;
 
  color: #1b3313;
  font-size: 28px;
  margin: 1vmax 0;
 
}
.detailsBlock-3-1 {

    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 3fr;



}
.detailsBlock-3-1-1{
 
    align-items: center;
    background: #fcf9f3;
    border: 1px solid rgba(34,66,41,.16);
    border-radius: 0;
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-right: 8px;

}
.detailsBlock-3-1-1 > button {

  background: transparent;
  border: 0;
  border-radius: 12px;
  height: 24px;
  margin: 8px;
  outline: 0;
  padding: 2px;
  width: 24px;

}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(111, 106, 106, 0.767);
}

.detailsBlock-3-1-1 > input {

  background: transparent;
  border: 0;
  font-family: Circular,sans-serif;
  font-size: 18px;
  font-weight: 400;
  min-width: 32px;
  outline: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.detailsBlock-3-1 > button:last-child , .btnadd , .btnadde , .btnlargeadde{

  margin: 0;
  width: 100%;

  background: #224229;
  border: 1px solid #224229;
  border-radius: 0;
  color: #fcf9f3;
  cursor: pointer;
  display: inline-block;

  padding: 16px 32px;
  text-align: center;
  text-decoration: none!important;
  transition: .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  font-family: Raisonne Demibold, Poppins ,sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .1em;
  line-height: 1.4;
  margin-top: 0;
  text-transform: uppercase;

  
}
.btnadd{
  margin: 0 ;
  height: 27px;
  line-height: 0.3;
  font-size: 13px;
  border-radius: 0;
}
.btnadde , .btnlargeadde{
  margin: 0 ;
  height: 27px;
  line-height: 0.3;
  font-size: 13px;
  border-radius: 0;
  color:#224229 ;
  background: #ffffff;
  padding: 19px 43px;
  margin-left: 3px;

}
.btnlargeadde:hover,.btnadde:hover{
  background: #469435;
  color: white;
}
.btnlargeadde{
  margin-left: 0;
  padding: 25px 21px;
  
}
.realprice{
  padding: 8px;
  font-size: 17px;
  text-decoration: line-through;
  color: #9d9999;
  list-style: none;
}



.detailsBlock-3-1 > button:last-child:hover {
  background-color: #18662f;
}

.detailsBlock-3 > p {
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  padding: 1vmax 0;
  color: rgba(0, 0, 0, 0.651);
  font: 400 1vmax "Roboto";
  margin: 1vmax 0;
}

.detailsBlock-4 {
  color: rgba(0, 0, 0, 0.897);
  /* font: 500 1.2vmax sans-serif; */
  font: 24px Nib,serif;
  margin-top: 44px;
  
}

.detailsBlock-4 > p {
  font-family: Raisonne Demibold, Poppins;
  color: #224229;
  font-size: 16px;
  line-height: 150%;
 
}

.submitReview {
  border: 1px color#093f19;
  background-color: #ffffff;
  font: 500 0.8vmax "Roboto";
  border-radius: 0;
  padding: 0.6vmax 2vmax;
  margin: 1vmax 0;
  color: #093f19;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
}
.submitReview:hover {
  background-color:#18662f;
  transform: scale(1.1);
  color: #ffffff;
}

.submitDialog {
  display: flex;
  flex-direction: column;
}
.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem "Roboto";
}

.reviewsHeading {
  color: #000000be;
  font: 500 1.4vmax "Roboto";
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 20vmax;
  margin: auto;
  margin-bottom: 4vmax;
}
.reviews {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.reviewCard {
  flex: none;

  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.226);
  border: 1px solid rgba(56, 56, 56, 0.116); */
  /* width: 30vmax; */
  width: 54vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vmax;
  padding: 3vmax;
  border: 1px solid rgba(0, 0, 0, 0.082);
  background-color: #fbfbfb;
}
.reviewCard > img {
  width: 5vmax;
}
.reviewCard > p {
  color: rgba(0, 0, 0, 0.836);
  font: 600 1vmax "Roboto";
}
.reviewCardComment {
  color: rgba(0, 0, 0, 0.445);
 font-size: 14px;
 letter-spacing:0.02rem;
  font-weight: 600;
  font-family: 'Roboto';
}


.noReviews {
  font: 400 1.3vmax "Gill Sans";
  
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

@media screen and (max-width: 600px) {
 
  .ProductDetails {
    flex-direction: column;
    height: unset;
  }

  /* new to */
  .size{
 justify-content: center; 

  }
  .sizeicon{
    margin: 10px;
   
 
  }

/* here */


  .ProductDetails > div:last-child {
    align-items: center;
  }

  .detailsBlock-1 > h2 {
    font-size: 2.8vmax;
    text-align: center;
  }

  .detailsBlock-1 > p {
    text-align: center;
    font-size: 17px;
    font-family: 'Chilanka';
 
  }

  .detailsBlock-2 {
    justify-content: center;
  }
  .justify{
    justify-content: flex-start;
  }

  .detailsBlock-2 > span {
    font-size: 1.5vmax;
  }

  .detailsBlock-3 > h1 {
    font: 700 3vmax "Franklin Gothic Medium";
    text-align: center;
  }

  .detailsBlock-3-1 {
    flex-direction: column;
  }

  .detailsBlock-3-1-1 > button {
    padding: 1.2vmax;
    width: 4vmax;
  }

  .detailsBlock-3-1-1 > input {
    padding: 1.5vmax;
    width: 3vmax;
    font: 400 1.8vmax "Roboto";
  }

  .CarouselImage {
    width: 45vmax;
  }

  .detailsBlock-3 > p {
    padding: 2.5vmax 0;
    text-align: center;
    font: 400 2vmax "Roboto";
  }

  .detailsBlock-4 {
    font: 500 2.5vmax sans-serif;
  }

  .submitReview {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .reviewCard > p {
    font: 600 4vw "Roboto";
  }
  .reviewCardComment {
  
    font-size: 12px;
     font-weight: 600;
    
  }
}
/* new adde */

.pdp-description {
  background: #f8f1e3;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: -45px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 0;
  width: 1440px;
}
@media (min-width: 991px){
  .pdp-description {
    box-sizing: border-box;
     gap: 0 40px;
     grid-template-columns: 1fr 1fr;
 }}
.pdp-description__text {
  align-self: center;
  margin: 40px auto;
  max-width: 600px;
  padding: 40px;
  color: #224229;

}
.buttonfont{
font-size: 11px;
}
h4 {
  font-family: Raisonne Demibold, Poppins;
  font-size: 24px;
  font-weight: 00;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  color: #224229;
}
.pdp-description__text p {
  line-height: 1.8;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  font-family: Raisonne Demibold, Poppins;
}

.pdp-description__image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;

  border-style: none;
}
@media (max-width: 990px){
  .reviewCard{
    width: 322px;
  }
.detailsBlock-3-1 {
  

    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 3fr;
}
.btnlargeadde{
  padding: 14px 21px;
}

 }

 @media (min-width: 1150px){
  .detailsBlock-4  > p{
     line-height: 206%;
  
     
  }
   }

   /* ne taxa s adde */
   .taxes{
    margin: 20px;
   }