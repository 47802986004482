
  .listproduct {
    font-size: 1.4rem;
    font-size: 14px;
    font-family: Raisonne Demibold, Poppins ,sans-serif;
  }
  /* new adde */
  .productpara{
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --tw-text-opacity: 1;
    color: rgb(129 127 124 / var(--tw-text-opacity));
    font-size: 0.80rem;
    /* line-height: 1.5rem; */
    list-style: none;
    text-align: left;
    line-height: 1.5rem;
}
.tagbadge{
  z-index: 20;

  margin-top: 7px;
    position: absolute;
}
.tagbadgein{
  padding:11px 15px 8px 19px;
  --tw-bg-opacity: 1;
    background-color: rgb(75 216 200 / var(--tw-bg-opacity));
    align-items: center;
    font-size: .75rem;
    line-height: 1rem;
    color: white;
   
    
    font-family: 'League Spartan', sans-serif;
}

  .page {
    width: 100%;
    max-width: 1200px;
    margin: 10px auto;
  }
  
.is--right {
    grid-column-start: 3;
  }
  
  .main1 {
    grid-row-start: 1;
    grid-column: span 2;
  }
 
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  
    grid-row-gap: 36px;

    margin-left: 6px;
    margin-right: 6px;
  }

  .grid__item {
    padding: 4px;
    /* background: rgb(247, 255, 252); */
    text-align: center;
  }

  .grid__item p{
    font-family: Raisonne Demibold,Poppins ,sans-serif;
    color: #224229;
    font-weight: 500;
  }
  
  .is--featured {
    background: rgb(255, 248, 248);
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
 
  .grid__item > img {
    width: 100%;
    height:100%
  }
  .grid__itemblog > img {
    width: 100%;
    height:200px;
    transition: .5s ease;
  }

  .grid__itemblog:hover img {
  
    transform: scale(1.1)

}
  .pricename{
    display: flex;
   justify-content: space-between;
   margin-top: 10px;


  }

  @media only screen and (min-width: 768px) {

    .grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

  }

  @media only screen and (max-width: 768px) {

    .productpara{
      font-size: .75rem;
      line-height: 1rem;
  }
  .tagbadgein{
    font-size: 10px;
    padding: 5px 10px 4px 12px;
  }
  .pricename{
 font-size: 14px;

  }
  .grid__itemblog > img {
  
    height: 150px;
  
}
  }
  @media only screen and (min-width: 480px) {

    .tagbadge{
      margin-top: 16px;
    }

    .tagbadgein{
     
      padding: 11px 15px 8px 19px;
      font-size: 17px;
      letter-spacing: 0.6px;
  }
}
  