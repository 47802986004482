@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lato&family=League+Spartan:wght@700;800&family=Lobster&family=Montserrat&family=Nunito+Sans:wght@200;600;700&family=Nunito:wght@500&family=Playfair+Display&family=Roboto+Condensed:wght@700&family=Roboto:wght@900&family=Source+Sans+Pro:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=League+Spartan:wght@700&family=Lobster&family=Nunito+Sans:wght@200;600;700&family=Yanone+Kaffeesatz:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=League+Spartan:wght@700&family=Lobster&family=Nunito+Sans:wght@200;600;700&family=Playfair+Display&family=Roboto:wght@900&display=swap);
* {
  margin: 0;
  scroll-behavior: smooth;
  text-decoration: none;
  
}


html, body {
  margin: 0;
  padding: 0;

  overflow-x: hidden;
  scrollbar-width: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.greenColor {
  color: green !important;
}
.redColor {
  color: red !important;
}


#siteAnnouncement {
    padding: 10px 15px;
    background-color: #224229;
 
  }
  #siteAnnouncement p {
    margin: 0;
    padding: 0;
    line-height: 1.3em;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    font-family: "Open Sans", sans-serif;
  }

.speedDial {
  position: absolute;
  right: 0vmax;
  top: 0.7vmax;

}

.speedDialIcon {
  width: 20px;
  height: 20px;
  border-radius: 100%;
 
}

.bodyofmenu{
  margin: 0;
  padding: 0;

  font-family:Raisonne Demibold, Poppins , sans-serif;
}

.bodyofmenu nav{
  position: sticky;
  z-index: 99;
  width: 100%;
  
  background: #ffffff;
}

nav .wrapper{
  position: relative;
 
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* new */
.logo{


  background-color: rgb(255, 255, 255);
  height: inherit;
  /* top: auto; */
  padding: inherit;
}
img{

  width: 145px;

}


.wrapper .nav-links{
  display: inline-flex;
}
.nav-links li{
  list-style: none;
}
.nav-links li a{
  /* color: #f2f2f2; */
  color: #0f3b0f;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 1.3px;
  font-weight: 500;
  /* padding: 9px 45px;  */
  padding: 9px 34px;
  border-radius: 25px;
  transition: all 0.3s ease;
}
.nav-links li a:hover{
  /* background: #e4e5e6; */
  text-decoration: underline;
}
.nav-links .mobile-item{
  display: none;
  letter-spacing: 1.5px;
}
.nav-links .drop-menu{
  position: absolute;
  background: #ffffff;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
font-size: 14px;
  
  border-radius: 0px;
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  /* padding: 0 30px; */
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content{
  background: #ffffff;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  justify-content: center;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-box .content .row{
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img{
  /* width: 100%; */
  width: 77%;
  height: 77%;
  object-fit: cover;
}
.content .row header{
  color: #050303;
  font-size: 16px;
  font-weight: 500;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
  font-size: 13px;
  color: #0f3b0f;
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: #181f19;
  font-size: 14px;
  display: block;
}
.row .mega-links li a:hover{
  color: #1a501d;
}
.wrapper .btn{
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn{
  position: absolute;
  right: 30px;
  top: 10px;
}
@media screen and (min-width: 1260px){
  .cart-mobile{
    visibility: hidden;

  }
  .close-btn{
    display: none;
  }
}

@media screen and (max-width: 1260px) {

  /* new adde */
  .speedDial {
    position: absolute;
right: 20vmax;
    top: 0.6vmax;
  }
.mrgbtm{
  margin-bottom: 20px;
}
  
.cart-mobile{
  margin-right: 18px;
  margin-bottom: 37px;
  color: #58a12b;
}

  nav .wrapper{
    
    padding: 0px 7px;
  }
  .close-btn{
    color: #ffffff;
    position: absolute;
    right: 30px;
    top: 10px;
  }
  
  .wrapper .btn{
    display: block;
    color: #000;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 75%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #224229;
    display: block;
    /* padding: 50px 10px; */
    padding: 30px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
   
  }

  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .nav-links li{
    margin: 15px -8px;
   
  }
  .nav-links li a{
    /* padding: 0 20px; */
    padding: 0 0px;
    display: block;
    font-size: 20px;
    color: #275a1c;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
    background: #faf5f4;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box, #showMegas:checked ~ .mega-box ,#showMegasx:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background:#224229;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    /* padding: 0 20px; */
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    /* background-color: #b32121; */
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    /* padding: 20px 20px 0 20px; */
    padding: 0px 0px 0px 0px;


    background-color: #faf5f4;
  }
  .mega-box .content .row{
    width: 100%;
    /* margin-bottom: 15px; */
    margin-bottom: -15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .content .row header{
    color:#4e8e3b;
   
  }
  .row .mega-links li a{
  
    color: #44652a;

  
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    /* padding-left: 15px; */
    padding-left: 36px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
  /* new adde */
  .content .row img{
    width: 100%;
  
    height: 100%;
    object-fit: cover;
  }
}
nav input{
  display: none;
}

.body-text{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div{
  font-size: 45px;
  font-weight: 600;
}

/* new adde */
 
@media screen and (min-width: 1200px) {
.nav-links {
  align-items: center;
  height: 20px;
}
}

@media screen and (max-width: 600px) {
 img{
  width: 122px;
 }
  }
.MuiStepConnector-line {
  display: none !important;
}

.MuiStepConnector-root {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.349);
}

.MuiStepConnector-active,
.MuiStepConnector-completed {
  background-color: tomato;
}

.confirmOrderPage {
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-columns: 6fr 3fr;
}

.confirmOrderPage > div:last-child {
  border-left: 1px solid rgba(0, 0, 0, 0.247);
}

.confirmshippingArea {
  padding: 5vmax;
  padding-bottom: 0%;
}

.confirmshippingArea > p {
  font: 500 3vw "Roboto";
}

.confirmshippingAreaBox,
.confirmCartItemsContainer {
  margin: 2vmax;
}

.confirmshippingAreaBox > div {
  display: flex;
  margin: 1vmax 0;
}

.confirmshippingAreaBox > div > p {
  font: 400 1vmax "Roboto";
  color: black;
}
.confirmshippingAreaBox > div > span {
  margin: 0 1vmax;
  font: 600 1vmax "Roboto";
  color: #575757;
}

.confirmCartItems > p {
  font: 400 1.8vmax "Roboto";
}

.confirmCartItems {
  padding: 5vmax;
  padding-top: 2vmax;
  max-height: 240px;
    overflow-y: auto;
}

/* .confirmCartItemsContainer {
  max-height: 20vmax;
  overflow-y: auto;
} */

.confirmCartItemsContainer > div {
  display: flex;
  font: 400 1vmax "Roboto";
  align-items: center;
  justify-content: space-between;
  margin: 2vmax 0;
}

/* .confirmCartItemsContainer > div > img {
  width: 3vmax;
} */

.confirmCartItemsContainer > div > a {
  color: #575757;
  margin: 0 2vmax;
  width: 60%;
  text-decoration: none;
}

.confirmCartItemsContainer > div > span {
  font: 100 1vmax "Roboto";
  color: #5e5e5e;
}

.orderSummary {
  padding: 7vmax;
}

.orderSummary > p {
  text-align: center;
  font: 400 1.8vmax "Roboto";
  border-bottom: 1px solid rgba(0, 0, 0, 0.267);
  padding: 1vmax;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

.orderSummary > div > div {
  display: flex;
  font: 300 1vmax "Roboto";
  justify-content: space-between;
  margin: 2vmax 0;
}
.orderSummary > div > div > span {
  color: rgba(0, 0, 0, 0.692);
}

.orderSummaryTotal {
  display: flex;
  font: 300 1vmax "Roboto";
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.363);
  padding: 2vmax 0;
}

.orderSummary > button {
  background: #224229;
  color: white;
  width: 100%;
  padding: 1vmax;
  border: none;
  margin: auto;
  cursor: pointer;
  transition: 0.5s;
  font: 400 1vmax "Roboto";
}

.orderSummary > button:hover {
  background-color: rgb(192, 71, 50);
}

@media screen and (max-width: 600px) {
  .confirmOrderPage {
    grid-template-columns: 1fr;
    height: unset;
  }

  .confirmOrderPage > div:last-child {
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.247);
  }

  .confirmshippingArea > p {
    font: 400 6vw "Roboto";
  }

  .confirmshippingAreaBox > div {
    display: flex;
    margin: 6vw 0;
  }

  .confirmshippingAreaBox > div > p {
    font: 400 4vw "Roboto";
  }
  .confirmshippingAreaBox > div > span {
    /* font: 503 4vw "Roboto"; */
    font: 600 1.8vmax "Roboto";
  }

  .confirmCartItems > p {
    font: 400 6vw "Roboto";
  }

  .confirmCartItemsContainer {
    max-height: 50vw;
  }

  .confirmCartItemsContainer > div {
    font: 400 3.5vw "Roboto";
    margin: 4vw 0;
  }

  .confirmCartItemsContainer > div > img {
    /* width: 10vw; */
    width: 16vw;
  }

  .confirmCartItemsContainer > div > a {
    margin: 0;
    width: 30%;
  }

  .confirmCartItemsContainer > div > span {
    font: 100 4vw "Roboto";
  }

  .confirmCartItems{
    padding: 1vmax;
  }

  .orderSummary {
    padding: 12vw;
  }

  .orderSummary > p {
    font: 400 6vw "Roboto";
    padding: 4vw;
  }

  .orderSummary > div > div {
    font: 300 4vw "Roboto";
  }

  .orderSummaryTotal {
    font: 300 4vw "Roboto";
    padding: 5vw 0;
  }

  .orderSummary > button {
    padding: 4vw;
    margin: 4vw auto;
    font: 400 4vw "Roboto";
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid rgba(0, 0, 0, 0.719);

  border-radius: 50%;

  -webkit-animation: loadingRotate 800ms linear infinite;

          animation: loadingRotate 800ms linear infinite;
}

@-webkit-keyframes loadingRotate {
  to {
    -webkit-transform: rotateZ(-360deg);
            transform: rotateZ(-360deg);
  }
}

@keyframes loadingRotate {
  to {
    -webkit-transform: rotateZ(-360deg);
            transform: rotateZ(-360deg);
  }
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  font-family: Raisonne Demibold, Poppins;
}

.question {
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}
.faq-faq{
  margin-bottom: 50px;
  font-size: 31px;
color: rgb(36, 45, 39);
}

.queston {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(16, 84, 36);
}
.queston:hover {
  color: rgb(25, 129, 39);
  cursor: pointer;
text-decoration: underline;
}


.anser {
  font-size: 18px;
  line-height: 1.5;
  color: rgb(61 108 75);
 
}

.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.dropdown-content p {
  margin-top: 18px;
    margin-bottom: 42px;
}

.question.open .dropdown-icon {
  -webkit-transform: translateY(-50%) rotate(-135deg);
          transform: translateY(-50%) rotate(-135deg);
}

.question.open .dropdown-content {
  max-height: 1000px;
}



/* new adde  */
.homepage{
  overflow: auto;
  justify-content: center;
}

.titles{
 display: flex;
}

.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.productCard {
  width: 295px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 9px;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
  background-color: rgb(255, 255, 255);

  /* new adde margin */
  margin-bottom: auto;
}

.productCard > img {
 

  
  width: 295px;

}
/* .realprice{
  padding: 8px;
  font-size: 17px;
 
} */
.realprice1{

  font-size: 13px;
  text-decoration: line-through;
  color: #9d9999;
  list-style: none;
}


.productCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productCardSpan {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}

.productCard > p {
  font-family: Raisonne DemiBold , Poppins;
 color: #2d2a24;
 font-size: 21px;
 line-height: 150%;
}

.productCard > span {

      color: #1f3720;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-size: 19PX;
      margin-bottom: 14px;
      margin-left: 212px;
      margin-top: -27px;
}

.productCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.25);

 
  -webkit-transform: translateY(-1vmax);

 
          transform: translateY(-1vmax);
}

/* NEW ADDEEEE FOR TEST from here */
.app__bg3NW {
  position: relative;
  overflow: hidden;
}

.app__headerNW {
  background-color: var(--color-black);
  font-family: Nib, serif;
}

.app__wrapperNW {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  min-height: 70vh;
}

.background-imageNW {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
/* here */



.app__wrapper_info {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
 padding-left: 54px;

}

.custom-btn {
 
  width: 157px;
  height: 40px;
  color: #fff;
  border-radius: 0;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}
.btn-widthh{
  width: 305px;
}
.btn-15 {
  background: #224229;
  border: none;
  z-index: 1;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #2c9223;
  /* border-radius: 25px; */
  border-radius: 0;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
  
}
.btn-15:hover {
  color: #fff;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}

/* hr seperater */


.hrline{
  margin-bottom: 56px;
  margin-top: 56px;
  
    margin: 0 64px;
    width: calc(100% - 128px);



    border: none;
    border-bottom: 2px solid;
    margin-left: auto;
    margin-right: auto;


  border-top: 1px solid;
  border-bottom: 1px solid;


    box-sizing: content-box;
    height: 0;
    overflow: visible;

 
    background: #224229;
    height: 2px;
    /* margin-bottom: 0!important;
    margin-top: 0!important; */
    opacity: 1;
    padding: 0!important;

}
/* Headings */

.mb-6 {  
  font-size: inherit;
  font-weight: 500;

  color: #224229;
  line-height: 10%;
  letter-spacing: -.01em;
  margin: 30px;
  font-size: 48px;
  margin-bottom:2.5rem;
 
}
.paraof{
  font-family: 'Poppins';
  text-align: justify !important;
    margin: 31px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.06rem;
    color: #224229;
}




/* new adde */
.gap {
  height: 60px;
  margin: 24px 0;
}
.gap1 {
  height: 10px;
  /* margin: 9px 0; */
}
.gap2{
  height: 36px;
}
@media (min-width: 768px){
.gap {
    margin: 36px 0;

}

.xbox{
  height: 650px;
}

}

@media (min-width: 480px){
.gap {
    margin: 30px 0;
}}





/* new */


.app__bg3 {
 
  background-position: center;
  background-size: contain;

  /* background-repeat: repeat; */
  /* background-attachment: fixed; */

 
}





.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
/* min-height: 100vh; */
min-height: 64vh;;
}

.app__header {
  background-color: var(--color-black);
  font-family: Nib,serif;

}

.app__header-h1 , .fontsizee{
  font-family: 'League Spartan', sans-serif;
  /* font-family: Nib,serif; */
  color:#ffffff ;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  font-size: 68px;
}

.fontsizee{
  font-size: 84px;
}

.p__opensans{
  font: 24px ;
  font-family: Raisonne Demibold, Poppins ,sans-serif;
  line-height: 1.4;
}

.app__header-img img {
  width: 80%;
}
.home{
  color: rgb(255, 255, 255);
}


@media screen and (min-width: 2000px) {
  .app__header-h1 {
    font-size: 180px;
    line-height: 210px;
  }
 
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 44px;
  }
  .productCard > p{
    font-size: 18px;
  }
  .app__wrapper_info{

    margin-top: 272px;;
     
     }
  
}

@media screen and (max-width: 650px) {
  .xbox{
    height: 350px;
  }
  
  .app__wrapperNW {
 
    min-height: 53vh;
  
  }
  .paraof{
    margin: 15px;
  }
  
  .app__header-h1 {
    font-size: 54px;  
  }
  .fontsizeofheader{
    font-size: 42px;
    margin-bottom: 31px;
  }
  .mb-6 {  
    font-size: inherit;
    font-weight: 500;
    
    color: #224229;
    line-height: 10%;
    letter-spacing: -.01em;
    margin: 16px;
    font-size: 24px;
    margin-bottom:2.5rem;
   
  }
  .realprice1{
    font-size: 11px;
  }
 
  .app__wrapper_info{

 margin-top: 272px;;
  
  }
}

@media screen and (max-width: 450px) {

  .app__header-h1 {
    font-size: 47pxl;
    /* line-height: 0px; */
    line-height: -1px;
    letter-spacing: 0px;
    margin-bottom:6px;
  }
  .fontof{
    font-size: 39px;
  }
  .app__wrapperNW {
 
    min-height: 53vh;
  
  }
  .fontsizeofheader{
    font-size: 42px;
    margin-bottom: 31px;
  }
  
}



/* new one */

.new{

  
  color: #fff;
  margin-top: 40px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  line-height: 2;
  font-size: 16px;

}
.new1{

  
  color: #fff;
  margin-top: 40px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  line-height: 2;
  font-size: 45px;

}
.new2{
 
    
   font-size: 20px;
   color: #224229;
    overflow-wrap: break-word;
    font-family: Raisonne Demibold, Poppins;

}
.new3{
 
    
  font-size: 14px;
  color: #224229;
   overflow-wrap: break-word;
   font-family: Raisonne Demibold, Poppins;

}



.container {
  width: 85%;
  max-width: 1400px;
  margin: auto;
}


.menu-btn {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 2;
  display: none;
}





/* Home cards */
.home-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 40px;
  width: 100%;

}

.home-cards img {
  width: 100%;
  /* margin-bottom: 20px; */
  border-radius: 20px;
}







/* Xbox */
.xbox {
  width: 100%;
  
  background: url('https://res.cloudinary.com/dc5luxn64/image/upload/v1731834323/Gallery/parkpalmlandscaping_qyqhrk.webp') no-repeat center center/cover;
  margin-bottom: 20px;
  border-radius: 20px;
}

.xbox .content {
  width: 40%;
  padding: 50px 0 0 30px;
}

.xbox p, .carbon p {
  margin: 10px 0 20px;
}

/* Carbon */
.carbon {
  width: 100%;
  height: 350px;
  background: url('https://i.ibb.co/72cgtsz/carbon.jpg') no-repeat center center/cover;
}

.carbon .content {
  width: 55%;
  padding: 100px 0 0 30px;
}








@media(max-width: 700px) {

  .new1{
    font-size: 28px;
  }
  .menu-btn {
    display: block;
  }

  .menu-btn:hover {
    opacity: 0.5;
  }

  .main-nav ul.right-menu {
    margin-right: 50px;
  }

  .main-nav ul.main-menu {
    display: block;
    position: absolute;
    top:0;
    left: 0;
    background: #f2f2f2;
    width: 50%;
    height: 100%;
    border-right: #ccc 1px solid;
    opacity: 0.9;
    padding: 30px;
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  }

  .main-nav ul.main-menu li {
    padding: 10px;
    border-bottom: #ccc solid 1px;
    font-size: 14px;
  }

  .main-nav ul.main-menu li:last-child {
    border-bottom: 0;
  }

  .main-nav ul.main-menu.show {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }

  .home-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .xbox .content p {
    display: none;
  }

  .xbox .content h2 {
    margin-bottom: 20px;
  }

  .carbon .content {
    width: 85%;
  }

  .links .links-inner {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width: 500px) {
  .home-cards {
    grid-template-columns: 1fr 1fr;
  }

  .links .links-inner {
    grid-template-columns: 1fr;
  }

  .links .links-inner ul {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .new3{
    font-size: 24px;
  }
  
}



  .listproduct {
    font-size: 1.4rem;
    font-size: 14px;
    font-family: Raisonne Demibold, Poppins ,sans-serif;
  }
  /* new adde */
  .productpara{
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --tw-text-opacity: 1;
    color: rgb(129 127 124 / var(--tw-text-opacity));
    font-size: 0.80rem;
    /* line-height: 1.5rem; */
    list-style: none;
    text-align: left;
    line-height: 1.5rem;
}
.tagbadge{
  z-index: 20;

  margin-top: 7px;
    position: absolute;
}
.tagbadgein{
  padding:11px 15px 8px 19px;
  --tw-bg-opacity: 1;
    background-color: rgb(75 216 200 / var(--tw-bg-opacity));
    align-items: center;
    font-size: .75rem;
    line-height: 1rem;
    color: white;
   
    
    font-family: 'League Spartan', sans-serif;
}

  .page {
    width: 100%;
    max-width: 1200px;
    margin: 10px auto;
  }
  
.is--right {
    grid-column-start: 3;
  }
  
  .main1 {
    grid-row-start: 1;
    grid-column: span 2;
  }
 
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  
    grid-row-gap: 36px;

    margin-left: 6px;
    margin-right: 6px;
  }

  .grid__item {
    padding: 4px;
    /* background: rgb(247, 255, 252); */
    text-align: center;
  }

  .grid__item p{
    font-family: Raisonne Demibold,Poppins ,sans-serif;
    color: #224229;
    font-weight: 500;
  }
  
  .is--featured {
    background: rgb(255, 248, 248);
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
 
  .grid__item > img {
    width: 100%;
    height:100%
  }
  .grid__itemblog > img {
    width: 100%;
    height:200px;
    transition: .5s ease;
  }

  .grid__itemblog:hover img {
  
    -webkit-transform: scale(1.1);
  
            transform: scale(1.1)

}
  .pricename{
    display: flex;
   justify-content: space-between;
   margin-top: 10px;


  }

  @media only screen and (min-width: 768px) {

    .grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

  }

  @media only screen and (max-width: 768px) {

    .productpara{
      font-size: .75rem;
      line-height: 1rem;
  }
  .tagbadgein{
    font-size: 10px;
    padding: 5px 10px 4px 12px;
  }
  .pricename{
 font-size: 14px;

  }
  .grid__itemblog > img {
  
    height: 150px;
  
}
  }
  @media only screen and (min-width: 480px) {

    .tagbadge{
      margin-top: 16px;
    }

    .tagbadgein{
     
      padding: 11px 15px 8px 19px;
      font-size: 17px;
      letter-spacing: 0.6px;
  }
}
  
.ProductDetails {
  background-color: rgb(255, 255, 255);

  max-width: 100%;

  box-sizing: border-box;
  display: flex;
}

.ProductDetails > div {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 2vmax;
  box-sizing: border-box;
  border: 1px solid white;
}

.ProductDetails > div:last-child {
  align-items: flex-start;
}

.CarouselImage {
  width: 48vmax;
}
.detailsBlock-1 {
  margin-bottom: 20px;
}

.detailsBlock-1 > h2 {

  font-family: Domain display;

  color: #214216;
  font-size: 31px;
  line-height: 150%;

}

/* new from here  */

.size{

  list-style: none;
  display: flex;
  
  padding: 10px;
  
}

.sizeicon{

  border: 1px solid #dbdbdb;
    padding: 8px;
    margin: 4px;
    cursor: pointer;
}
.clicked {
  background-color: #ffcc00; /* Change to the desired background color when clicked */
}
/* to here */

.detailsBlock-1 > p {
  color: rgba(54, 54, 54, 0.582);
  font: 300 0.6vmax;
  font-size: 16px;
  font-family: 'Chilanka';
}

.detailsBlock-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 1vmax 0;
}

.detailsBlock-2-span {
  font: 200 0.8vmax cursive;
  color: rgba(0, 0, 0, 0.699);
}

.detailsBlock-3 {
  width: 100%;
}

.detailsBlock-3 > h1 {
 
  font-family: Domain display;
 
  color: #1b3313;
  font-size: 28px;
  margin: 1vmax 0;
 
}
.detailsBlock-3-1 {

    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 3fr;



}
.detailsBlock-3-1-1{
 
    align-items: center;
    background: #fcf9f3;
    border: 1px solid rgba(34,66,41,.16);
    border-radius: 0;
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-right: 8px;

}
.detailsBlock-3-1-1 > button {

  background: transparent;
  border: 0;
  border-radius: 12px;
  height: 24px;
  margin: 8px;
  outline: 0;
  padding: 2px;
  width: 24px;

}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(111, 106, 106, 0.767);
}

.detailsBlock-3-1-1 > input {

  background: transparent;
  border: 0;
  font-family: Circular,sans-serif;
  font-size: 18px;
  font-weight: 400;
  min-width: 32px;
  outline: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.detailsBlock-3-1 > button:last-child , .btnadd , .btnadde , .btnlargeadde{

  margin: 0;
  width: 100%;

  background: #224229;
  border: 1px solid #224229;
  border-radius: 0;
  color: #fcf9f3;
  cursor: pointer;
  display: inline-block;

  padding: 16px 32px;
  text-align: center;
  text-decoration: none!important;
  transition: .2s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;


  font-family: Raisonne Demibold, Poppins ,sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .1em;
  line-height: 1.4;
  margin-top: 0;
  text-transform: uppercase;

  
}
.btnadd{
  margin: 0 ;
  height: 27px;
  line-height: 0.3;
  font-size: 13px;
  border-radius: 0;
}
.btnadde , .btnlargeadde{
  margin: 0 ;
  height: 27px;
  line-height: 0.3;
  font-size: 13px;
  border-radius: 0;
  color:#224229 ;
  background: #ffffff;
  padding: 19px 43px;
  margin-left: 3px;

}
.btnlargeadde:hover,.btnadde:hover{
  background: #469435;
  color: white;
}
.btnlargeadde{
  margin-left: 0;
  padding: 25px 21px;
  
}
.realprice{
  padding: 8px;
  font-size: 17px;
  text-decoration: line-through;
  color: #9d9999;
  list-style: none;
}



.detailsBlock-3-1 > button:last-child:hover {
  background-color: #18662f;
}

.detailsBlock-3 > p {
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  padding: 1vmax 0;
  color: rgba(0, 0, 0, 0.651);
  font: 400 1vmax "Roboto";
  margin: 1vmax 0;
}

.detailsBlock-4 {
  color: rgba(0, 0, 0, 0.897);
  /* font: 500 1.2vmax sans-serif; */
  font: 24px Nib,serif;
  margin-top: 44px;
  
}

.detailsBlock-4 > p {
  font-family: Raisonne Demibold, Poppins;
  color: #224229;
  font-size: 16px;
  line-height: 150%;
 
}

.submitReview {
  border: 1px color#093f19;
  background-color: #ffffff;
  font: 500 0.8vmax "Roboto";
  border-radius: 0;
  padding: 0.6vmax 2vmax;
  margin: 1vmax 0;
  color: #093f19;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
}
.submitReview:hover {
  background-color:#18662f;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: #ffffff;
}

.submitDialog {
  display: flex;
  flex-direction: column;
}
.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem "Roboto";
}

.reviewsHeading {
  color: #000000be;
  font: 500 1.4vmax "Roboto";
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 20vmax;
  margin: auto;
  margin-bottom: 4vmax;
}
.reviews {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.reviewCard {
  flex: none;

  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.226);
  border: 1px solid rgba(56, 56, 56, 0.116); */
  /* width: 30vmax; */
  width: 54vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vmax;
  padding: 3vmax;
  border: 1px solid rgba(0, 0, 0, 0.082);
  background-color: #fbfbfb;
}
.reviewCard > img {
  width: 5vmax;
}
.reviewCard > p {
  color: rgba(0, 0, 0, 0.836);
  font: 600 1vmax "Roboto";
}
.reviewCardComment {
  color: rgba(0, 0, 0, 0.445);
 font-size: 14px;
 letter-spacing:0.02rem;
  font-weight: 600;
  font-family: 'Roboto';
}


.noReviews {
  font: 400 1.3vmax "Gill Sans";
  
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

@media screen and (max-width: 600px) {
 
  .ProductDetails {
    flex-direction: column;
    height: unset;
  }

  /* new to */
  .size{
 justify-content: center; 

  }
  .sizeicon{
    margin: 10px;
   
 
  }

/* here */


  .ProductDetails > div:last-child {
    align-items: center;
  }

  .detailsBlock-1 > h2 {
    font-size: 2.8vmax;
    text-align: center;
  }

  .detailsBlock-1 > p {
    text-align: center;
    font-size: 17px;
    font-family: 'Chilanka';
 
  }

  .detailsBlock-2 {
    justify-content: center;
  }
  .justify{
    justify-content: flex-start;
  }

  .detailsBlock-2 > span {
    font-size: 1.5vmax;
  }

  .detailsBlock-3 > h1 {
    font: 700 3vmax "Franklin Gothic Medium";
    text-align: center;
  }

  .detailsBlock-3-1 {
    flex-direction: column;
  }

  .detailsBlock-3-1-1 > button {
    padding: 1.2vmax;
    width: 4vmax;
  }

  .detailsBlock-3-1-1 > input {
    padding: 1.5vmax;
    width: 3vmax;
    font: 400 1.8vmax "Roboto";
  }

  .CarouselImage {
    width: 45vmax;
  }

  .detailsBlock-3 > p {
    padding: 2.5vmax 0;
    text-align: center;
    font: 400 2vmax "Roboto";
  }

  .detailsBlock-4 {
    font: 500 2.5vmax sans-serif;
  }

  .submitReview {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .reviewCard > p {
    font: 600 4vw "Roboto";
  }
  .reviewCardComment {
  
    font-size: 12px;
     font-weight: 600;
    
  }
}
/* new adde */

.pdp-description {
  background: #f8f1e3;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: -45px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 0;
  width: 1440px;
}
@media (min-width: 991px){
  .pdp-description {
    box-sizing: border-box;
     grid-gap: 0 40px;
     gap: 0 40px;
     grid-template-columns: 1fr 1fr;
 }}
.pdp-description__text {
  align-self: center;
  margin: 40px auto;
  max-width: 600px;
  padding: 40px;
  color: #224229;

}
.buttonfont{
font-size: 11px;
}
h4 {
  font-family: Raisonne Demibold, Poppins;
  font-size: 24px;
  font-weight: 00;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  color: #224229;
}
.pdp-description__text p {
  line-height: 1.8;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  font-family: Raisonne Demibold, Poppins;
}

.pdp-description__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;

  border-style: none;
}
@media (max-width: 990px){
  .reviewCard{
    width: 322px;
  }
.detailsBlock-3-1 {
  

    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 3fr;
}
.btnlargeadde{
  padding: 14px 21px;
}

 }

 @media (min-width: 1150px){
  .detailsBlock-4  > p{
     line-height: 206%;
  
     
  }
   }

   /* ne taxa s adde */
   .taxes{
    margin: 20px;
   }
.app__gallery {
    flex-direction: row;

    /* background: var(--color-black); */
    /* padding: 4rem 0 4rem 6rem; */
    padding: 4rem 0 4rem 2rem;
}

.app__gallery-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    position: relative;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: -webkit-max-content;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    /* min-width: 301px; */
    height: 447px;
 min-width: 300px;
    /* height: 300px; */

    margin-right: 2rem;
}

.gallery__image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  
    opacity: 1;
    transition: .5s ease;
}

.app__gallery-images_card:hover img {
  
        -webkit-transform: scale(1.1);
  
                transform: scale(1.1)
    
}
.full-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  
  .image-container:hover .full-image {
    display: block;
  }





.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* padding: 0 1rem; */
    position: absolute;
    bottom: -14%;
}

.gallery__arrow-icon {
    color: rgb(15, 40, 15);
    font-size: 6rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 5px;
}

.gallery__arrow-icon:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }

    .gallery__arrow-icon{
        font-size: 4rem;
    }
    .app__gallery-images_arrows{
        bottom: -17%;
    }
}









.sidebar {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
}

.sidebar > a:first-child {
  padding: 0;
}
.sidebar > a > img {
  width: 100%;
  transition: all 0.5s;
}

.sidebar > a > img:hover {
  -webkit-filter: drop-shadow(0 0 10px rgb(108, 72, 66));
          filter: drop-shadow(0 0 10px rgb(108, 72, 66));
}
.sidebar a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.493);
  font: 200 1rem "Roboto";
  padding: 2rem;
  transition: all 0.5s;
}
.sidebar a:hover {
  color: rgb(93, 208, 87);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.sidebar a > P {
  display: flex;
  align-items: center;
}
.sidebar a > p > svg {
  margin-right: 0.5rem;
}

.MuiTypography-root {
  background-color: #fff !important;
}

/* WhatsAppIcon.css */
.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }
  
  .whatsapp-icon {
    font-size: 30px;
    color: white;
  }
  
  .whatsapp-float:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .whatsapp-float {
      width: 50px;
      height: 50px;
      bottom: 15px;
      right: 15px;
      font-size: 25px;
    }
  
    .whatsapp-icon {
      font-size: 25px;
    }
  }
  
  @media (max-width: 480px) {
    .whatsapp-float {
      width: 45px;
      height: 45px;
      bottom: 10px;
      right: 10px;
      font-size: 20px;
    }
  
    .whatsapp-icon {
      font-size: 20px;
    }
  }
  
