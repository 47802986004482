
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=League+Spartan:wght@700&family=Lobster&family=Nunito+Sans:wght@200;600;700&family=Yanone+Kaffeesatz:wght@300&display=swap');

/* new adde  */
.homepage{
  overflow: auto;
  justify-content: center;
}

.titles{
 display: flex;
}

.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.productCard {
  width: 295px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 9px;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
  background-color: rgb(255, 255, 255);

  /* new adde margin */
  margin-bottom: auto;
}

.productCard > img {
 

  
  width: 295px;

}
/* .realprice{
  padding: 8px;
  font-size: 17px;
 
} */
.realprice1{

  font-size: 13px;
  text-decoration: line-through;
  color: #9d9999;
  list-style: none;
}


.productCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productCardSpan {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}

.productCard > p {
  font-family: Raisonne DemiBold , Poppins;
 color: #2d2a24;
 font-size: 21px;
 line-height: 150%;
}

.productCard > span {

      color: #1f3720;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-size: 19PX;
      margin-bottom: 14px;
      margin-left: 212px;
      margin-top: -27px;
}

.productCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.25);

 
  transform: translateY(-1vmax);
}

/* NEW ADDEEEE FOR TEST from here */
.app__bg3NW {
  position: relative;
  overflow: hidden;
}

.app__headerNW {
  background-color: var(--color-black);
  font-family: Nib, serif;
}

.app__wrapperNW {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  min-height: 70vh;
}

.background-imageNW {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
/* here */



.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
 padding-left: 54px;

}

.custom-btn {
 
  width: 157px;
  height: 40px;
  color: #fff;
  border-radius: 0;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}
.btn-widthh{
  width: 305px;
}
.btn-15 {
  background: #224229;
  border: none;
  z-index: 1;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #2c9223;
  /* border-radius: 25px; */
  border-radius: 0;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
  
}
.btn-15:hover {
  color: #fff;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}

/* hr seperater */


.hrline{
  margin-bottom: 56px;
  margin-top: 56px;
  
    margin: 0 64px;
    width: calc(100% - 128px);



    border: none;
    border-bottom: 2px solid;
    margin-left: auto;
    margin-right: auto;


  border-top: 1px solid;
  border-bottom: 1px solid;


    box-sizing: content-box;
    height: 0;
    overflow: visible;

 
    background: #224229;
    height: 2px;
    /* margin-bottom: 0!important;
    margin-top: 0!important; */
    opacity: 1;
    padding: 0!important;

}
/* Headings */

.mb-6 {  
  font-size: inherit;
  font-weight: 500;

  color: #224229;
  line-height: 10%;
  letter-spacing: -.01em;
  margin: 30px;
  font-size: 48px;
  margin-bottom:2.5rem;
 
}
.paraof{
  font-family: 'Poppins';
  text-align: justify !important;
    margin: 31px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.06rem;
    color: #224229;
}




/* new adde */
.gap {
  height: 60px;
  margin: 24px 0;
}
.gap1 {
  height: 10px;
  /* margin: 9px 0; */
}
.gap2{
  height: 36px;
}
@media (min-width: 768px){
.gap {
    margin: 36px 0;

}

.xbox{
  height: 650px;
}

}

@media (min-width: 480px){
.gap {
    margin: 30px 0;
}}





/* new */


.app__bg3 {
 
  background-position: center;
  background-size: contain;

  /* background-repeat: repeat; */
  /* background-attachment: fixed; */

 
}





.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
/* min-height: 100vh; */
min-height: 64vh;;
}

.app__header {
  background-color: var(--color-black);
  font-family: Nib,serif;

}

.app__header-h1 , .fontsizee{
  font-family: 'League Spartan', sans-serif;
  /* font-family: Nib,serif; */
  color:#ffffff ;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  font-size: 68px;
}

.fontsizee{
  font-size: 84px;
}

.p__opensans{
  font: 24px ;
  font-family: Raisonne Demibold, Poppins ,sans-serif;
  line-height: 1.4;
}

.app__header-img img {
  width: 80%;
}
.home{
  color: rgb(255, 255, 255);
}


@media screen and (min-width: 2000px) {
  .app__header-h1 {
    font-size: 180px;
    line-height: 210px;
  }
 
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 44px;
  }
  .productCard > p{
    font-size: 18px;
  }
  .app__wrapper_info{

    margin-top: 272px;;
     
     }
  
}

@media screen and (max-width: 650px) {
  .xbox{
    height: 350px;
  }
  
  .app__wrapperNW {
 
    min-height: 53vh;
  
  }
  .paraof{
    margin: 15px;
  }
  
  .app__header-h1 {
    font-size: 54px;  
  }
  .fontsizeofheader{
    font-size: 42px;
    margin-bottom: 31px;
  }
  .mb-6 {  
    font-size: inherit;
    font-weight: 500;
    
    color: #224229;
    line-height: 10%;
    letter-spacing: -.01em;
    margin: 16px;
    font-size: 24px;
    margin-bottom:2.5rem;
   
  }
  .realprice1{
    font-size: 11px;
  }
 
  .app__wrapper_info{

 margin-top: 272px;;
  
  }
}

@media screen and (max-width: 450px) {

  .app__header-h1 {
    font-size: 47pxl;
    /* line-height: 0px; */
    line-height: -1px;
    letter-spacing: 0px;
    margin-bottom:6px;
  }
  .fontof{
    font-size: 39px;
  }
  .app__wrapperNW {
 
    min-height: 53vh;
  
  }
  .fontsizeofheader{
    font-size: 42px;
    margin-bottom: 31px;
  }
  
}



/* new one */

.new{

  
  color: #fff;
  margin-top: 40px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  line-height: 2;
  font-size: 16px;

}
.new1{

  
  color: #fff;
  margin-top: 40px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  line-height: 2;
  font-size: 45px;

}
.new2{
 
    
   font-size: 20px;
   color: #224229;
    overflow-wrap: break-word;
    font-family: Raisonne Demibold, Poppins;

}
.new3{
 
    
  font-size: 14px;
  color: #224229;
   overflow-wrap: break-word;
   font-family: Raisonne Demibold, Poppins;

}



.container {
  width: 85%;
  max-width: 1400px;
  margin: auto;
}


.menu-btn {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 2;
  display: none;
}





/* Home cards */
.home-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 40px;
  width: 100%;

}

.home-cards img {
  width: 100%;
  /* margin-bottom: 20px; */
  border-radius: 20px;
}







/* Xbox */
.xbox {
  width: 100%;
  
  background: url('https://res.cloudinary.com/dc5luxn64/image/upload/v1731834323/Gallery/parkpalmlandscaping_qyqhrk.webp') no-repeat center center/cover;
  margin-bottom: 20px;
  border-radius: 20px;
}

.xbox .content {
  width: 40%;
  padding: 50px 0 0 30px;
}

.xbox p, .carbon p {
  margin: 10px 0 20px;
}

/* Carbon */
.carbon {
  width: 100%;
  height: 350px;
  background: url('https://i.ibb.co/72cgtsz/carbon.jpg') no-repeat center center/cover;
}

.carbon .content {
  width: 55%;
  padding: 100px 0 0 30px;
}








@media(max-width: 700px) {

  .new1{
    font-size: 28px;
  }
  .menu-btn {
    display: block;
  }

  .menu-btn:hover {
    opacity: 0.5;
  }

  .main-nav ul.right-menu {
    margin-right: 50px;
  }

  .main-nav ul.main-menu {
    display: block;
    position: absolute;
    top:0;
    left: 0;
    background: #f2f2f2;
    width: 50%;
    height: 100%;
    border-right: #ccc 1px solid;
    opacity: 0.9;
    padding: 30px;
    transform: translateX(-500px);
    transition: transform 0.5s ease-in-out;
  }

  .main-nav ul.main-menu li {
    padding: 10px;
    border-bottom: #ccc solid 1px;
    font-size: 14px;
  }

  .main-nav ul.main-menu li:last-child {
    border-bottom: 0;
  }

  .main-nav ul.main-menu.show {
    transform: translateX(-20px);
  }

  .home-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .xbox .content p {
    display: none;
  }

  .xbox .content h2 {
    margin-bottom: 20px;
  }

  .carbon .content {
    width: 85%;
  }

  .links .links-inner {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width: 500px) {
  .home-cards {
    grid-template-columns: 1fr 1fr;
  }

  .links .links-inner {
    grid-template-columns: 1fr;
  }

  .links .links-inner ul {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .new3{
    font-size: 24px;
  }
  
}

