/* WhatsAppIcon.css */
.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }
  
  .whatsapp-icon {
    font-size: 30px;
    color: white;
  }
  
  .whatsapp-float:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .whatsapp-float {
      width: 50px;
      height: 50px;
      bottom: 15px;
      right: 15px;
      font-size: 25px;
    }
  
    .whatsapp-icon {
      font-size: 25px;
    }
  }
  
  @media (max-width: 480px) {
    .whatsapp-float {
      width: 45px;
      height: 45px;
      bottom: 10px;
      right: 10px;
      font-size: 20px;
    }
  
    .whatsapp-icon {
      font-size: 20px;
    }
  }
  