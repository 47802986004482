@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lato&family=League+Spartan:wght@700;800&family=Lobster&family=Montserrat&family=Nunito+Sans:wght@200;600;700&family=Nunito:wght@500&family=Playfair+Display&family=Roboto+Condensed:wght@700&family=Roboto:wght@900&family=Source+Sans+Pro:wght@600&display=swap');

.speedDial {
  position: absolute;
  right: 0vmax;
  top: 0.7vmax;

}

.speedDialIcon {
  width: 20px;
  height: 20px;
  border-radius: 100%;
 
}

.bodyofmenu{
  margin: 0;
  padding: 0;

  font-family:Raisonne Demibold, Poppins , sans-serif;
}

.bodyofmenu nav{
  position: sticky;
  z-index: 99;
  width: 100%;
  
  background: #ffffff;
}

nav .wrapper{
  position: relative;
 
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* new */
.logo{


  background-color: rgb(255, 255, 255);
  height: inherit;
  /* top: auto; */
  padding: inherit;
}
img{

  width: 145px;

}


.wrapper .nav-links{
  display: inline-flex;
}
.nav-links li{
  list-style: none;
}
.nav-links li a{
  /* color: #f2f2f2; */
  color: #0f3b0f;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 1.3px;
  font-weight: 500;
  /* padding: 9px 45px;  */
  padding: 9px 34px;
  border-radius: 25px;
  transition: all 0.3s ease;
}
.nav-links li a:hover{
  /* background: #e4e5e6; */
  text-decoration: underline;
}
.nav-links .mobile-item{
  display: none;
  letter-spacing: 1.5px;
}
.nav-links .drop-menu{
  position: absolute;
  background: #ffffff;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
font-size: 14px;
  
  border-radius: 0px;
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  /* padding: 0 30px; */
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content{
  background: #ffffff;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  justify-content: center;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-box .content .row{
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img{
  /* width: 100%; */
  width: 77%;
  height: 77%;
  object-fit: cover;
}
.content .row header{
  color: #050303;
  font-size: 16px;
  font-weight: 500;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
  font-size: 13px;
  color: #0f3b0f;
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: #181f19;
  font-size: 14px;
  display: block;
}
.row .mega-links li a:hover{
  color: #1a501d;
}
.wrapper .btn{
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn{
  position: absolute;
  right: 30px;
  top: 10px;
}
@media screen and (min-width: 1260px){
  .cart-mobile{
    visibility: hidden;

  }
  .close-btn{
    display: none;
  }
}

@media screen and (max-width: 1260px) {

  /* new adde */
  .speedDial {
    position: absolute;
right: 20vmax;
    top: 0.6vmax;
  }
.mrgbtm{
  margin-bottom: 20px;
}
  
.cart-mobile{
  margin-right: 18px;
  margin-bottom: 37px;
  color: #58a12b;
}

  nav .wrapper{
    
    padding: 0px 7px;
  }
  .close-btn{
    color: #ffffff;
    position: absolute;
    right: 30px;
    top: 10px;
  }
  
  .wrapper .btn{
    display: block;
    color: #000;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 75%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #224229;
    display: block;
    /* padding: 50px 10px; */
    padding: 30px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
   
  }

  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .nav-links li{
    margin: 15px -8px;
   
  }
  .nav-links li a{
    /* padding: 0 20px; */
    padding: 0 0px;
    display: block;
    font-size: 20px;
    color: #275a1c;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
    background: #faf5f4;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box, #showMegas:checked ~ .mega-box ,#showMegasx:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background:#224229;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    /* padding: 0 20px; */
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    /* background-color: #b32121; */
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    /* padding: 20px 20px 0 20px; */
    padding: 0px 0px 0px 0px;


    background-color: #faf5f4;
  }
  .mega-box .content .row{
    width: 100%;
    /* margin-bottom: 15px; */
    margin-bottom: -15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .content .row header{
    color:#4e8e3b;
   
  }
  .row .mega-links li a{
  
    color: #44652a;

  
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    /* padding-left: 15px; */
    padding-left: 36px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
  /* new adde */
  .content .row img{
    width: 100%;
  
    height: 100%;
    object-fit: cover;
  }
}
nav input{
  display: none;
}

.body-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div{
  font-size: 45px;
  font-weight: 600;
}

/* new adde */
 
@media screen and (min-width: 1200px) {
.nav-links {
  align-items: center;
  height: 20px;
}
}

@media screen and (max-width: 600px) {
 img{
  width: 122px;
 }
  }