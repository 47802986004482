.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  font-family: Raisonne Demibold, Poppins;
}

.question {
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}
.faq-faq{
  margin-bottom: 50px;
  font-size: 31px;
color: rgb(36, 45, 39);
}

.queston {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(16, 84, 36);
}
.queston:hover {
  color: rgb(25, 129, 39);
  cursor: pointer;
text-decoration: underline;
}


.anser {
  font-size: 18px;
  line-height: 1.5;
  color: rgb(61 108 75);
 
}

.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.dropdown-content p {
  margin-top: 18px;
    margin-bottom: 42px;
}

.question.open .dropdown-icon {
  transform: translateY(-50%) rotate(-135deg);
}

.question.open .dropdown-content {
  max-height: 1000px;
}

